:root {
  --background-color: #1e1e1e;
  --header-background: #2a2a2a;
  --text-color: #e0e0e0;
  --accent-color: #5CCAD1;
  --input-background: #444;
  --input-border-color: #5CCAD1;
  --table-row-even: #3a3a3a;
  --table-row-odd: #4a4a4a;
  --table-hover-color: #999999;
  --header-font: Helvetica, Arial, sans-serif;
}

.light-mode {
  --background-color: #f0f0f0;
  --header-background: #ffffff;
  --text-color: #000000;
  --accent-color: #5ccad1;
  --input-background: #ffffff;
  --input-border-color: #888888;
  --table-row-even: #ffffff;
  --table-row-odd: #f9f9f9;
  --table-hover-color: #808080;
  --header-font: Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  background-color: var(--background-color);
  min-height: 100vh;
  display: flow;
  font-family: var(--header-font);
  color: var(--text-color);
}

.App-header {
  padding: 10px;
  background-color: var(--header-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  flex-wrap: wrap;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Floating Upload Icon */
.upload-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  font-size: 24px;
  z-index: 100;
}

.upload-icon:hover {
  background-color: #48b1ba;
  transform: translateY(-2px);
}

/* Modal */
.upload-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal.show {
  display: flex;
}

.upload-modal-content {
  background-color: var(--header-background);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  position: relative;
  color: var(--text-color);
}

.upload-modal h2 {
  color: var(--accent-color);
  margin-bottom: 15px;
}

.upload-preview {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--text-color);
}

.remove-file {
  cursor: pointer;
  color: #e74c3c;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.upload-button {
  background-color: var(--accent-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  display: inline-block;
}

.upload-button:hover {
  background-color: #48b1ba;
}

.upload-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal-content {
  background-color: var(--header-background);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  position: relative;
  color: var(--text-color);
}

.upload-modal-content h2 {
  color: var(--accent-color);
  margin-bottom: 15px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
}

.upload-preview {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--text-color);
}

.remove-file {
  cursor: pointer;
  color: #e74c3c;
}

.submit-button {
  padding: 10px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #48b1ba;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 10px;
  width: 0%;
  background-color: var(--accent-color); /* Adjust based on your theme */
  border-radius: 5px;
  text-align: center;
  color: white;
  font-size: 12px;
}

.toggle-mode {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--accent-color);
  font-size: 24px;
  max-width: fit-content;
  align-self: center;
}

.nav-menu {
  position: relative;
  display: inline-block;
  margin-left: auto;
}

.nav-menu-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--accent-color);
}

.nav-menu-content {
  display: none;
  position: absolute;
  background-color: var(--header-background);
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 9;
  right: 0;
}

.nav-menu-content button {
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
}

.nav-menu-content button:hover {
  background-color: var(--accent-color);
  color: var(--background-color);
}

.nav-menu-content .nav-menu-link {
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.nav-menu-content .nav-menu-link:hover {
  background-color: var(--accent-color);
  color: var(--background-color);
}

.nav-menu:hover .nav-menu-content {
  display: block;
}

.logo {
  height: 70px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: var(--header-background);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 480px;
  width: 100%;
  margin-top: 20px;
}

.input-field {
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.input-container {
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}

.tooltip {
  visibility: hidden;
  width: 250px;
  background-color: var(--text-color);
  color: var(--background-color);
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.input-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.input-field-short {
  width: calc(77% - 60px);
  padding: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.submit-button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin: 10px;
}

.submit-button:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

h1, h2 {
  color: var(--accent-color);
  text-shadow: 1px 1px 2px #000;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.message, .success-message {
  color: green;
  margin-top: 10px;
}

.message-box {
  display: none;
}

.message-box.success {
  background-color: green;
  color: white;
}

.message-box.error {
  background-color: red;
  color: white;
}

.table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background-color: var(--header-background);
  border: 1px solid var(--accent-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 20px;
  padding: 20px;
  width: auto;
  text-align: left;
  color: var(--text-color);
  transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s;
  position: relative;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-width: 2px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--accent-color);
  margin-bottom: 10px;
}

.card-body {
  padding-top: 10px;
}

.card h3 {
  color: var(--accent-color);
  font-size: 1.5em;
  margin-bottom: 10px;
}

.card .details {
  margin-bottom: 10px;
}

.card .description-text {
  color: var(--table-hover-color);
  margin-top: 10px;
}

.card .view-details-btn {
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.card .view-details-btn:hover {
  background-color: #48b1ba;
  transform: translateY(-2px);
}

.card .id-text {
  color: #b0b0b0;
  font-size: 0.9em;
}

.thumbnail-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 130px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.thumbnail-container:hover .thumbnail-image {
  transform: scale(1.1);
}

.filter-input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
  width: 100%;
  max-width: 500px;
}

.export-btn {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  max-width: fit-content;
}

.export-btn:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

.ask-btn {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
}

.ask-btn:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

.answer-section {
  margin-top: 20px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: var(--text-color);
}

.reset-password-button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin: 10px;
}

.reset-password-button:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

/* Notes Field */
.notes-field {
  width: 90%;
  padding: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
  margin: 10px;
}

.notes-field:focus {
  height: 100px;
  transition: height 0.3s ease;
}

/* Save Notes Button */
.save-notes-btn {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 10px;
}

.save-notes-btn:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

img {
  max-width: 100%;
}

/* Modal Styling */
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  padding-top: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content Styles */
.modal-content {
  background-color: var(--header-background);
  color: var(--text-color);
  margin: auto;
  border: 1px solid var(--accent-color);
  width: 95%;
  max-height: 95%;
  overflow-y: auto;
  display: flex;
  padding-bottom: 20px;
  zoom: 90%;
}

.modal-content .modal-image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content .modal-image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-content .modal-image {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.modal-content .modal-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
  cursor: grab;
}

.modal-content .modal-details {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.modal-content .close {
  color: var(--text-color);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-content .close:hover,
.modal-content .close:focus {
  color: #aaa;
  text-decoration: none;
  cursor: pointer;
}

.modal-content .zoom-controls {
  margin-top: 10px;
  z-index: 2;
  padding: 10px 10px;
}

.zoom-btns {
  background-color: var(--accent-color);
  color: white;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin: 5px;
}

.modal-navigation-prev,
.modal-navigation-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--text-color);
    padding: 10px;
    cursor: pointer;
    border-radius: 10%;
    font-size: 1.5rem;
    z-index: 3;
}

.modal-navigation-prev {
    left: 0px;
}

.modal-navigation-next {
    right: 0px;
}

.modal-navigation-prev:hover,
.modal-navigation-next:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Table Styling for Modals */
.modal-content .table-container {
  width: 100%;
  overflow-x: auto; /* Horizontal scrolling for mobile */
}

.modal-content .line-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-right: 10px;
}

.modal-content .line-items-table th,
.modal-content .line-items-table td {
  padding: 8px 12px;
  text-align: left;
}

.modal-content .line-items-table th {
  background-color: #5ccad1d1;
  color: var(--text-color);
  font-weight: bold;
}

.modal-content .line-items-table tr:nth-child(even) {
  background-color: var(--table-row-even);
}

.modal-content .line-items-table tr:nth-child(odd) {
  background-color: var(--table-row-odd);
}

.modal-content .line-items-table tr:hover {
  background-color: var(--table-hover-color);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .table-container {
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

  .card {
    max-width: 100%;
    margin: 10px;
    padding: 15px;
  }

  .modal-content {
    flex-direction: column;
  }

  .modal-image-container {
    width: 100%;
    touch-action: pinch-zoom;
  }

  .tab {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .filter-input, .export-btn {
    max-width: 90%;
  }

  .thumbnail-container {
    width: 80px;
    height: 100px;
    zoom: 80%;
    opacity: 0.4;
  }
}

/* Sticky Tabs Styling */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: var(--header-background); /* Same as header background */
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3); /* Add slight shadow for separation */
  padding: 10px 0;
}

/* Tab Styles with Improved Visibility */
.tab {
  padding: 12px 20px; /* Increased padding */
  cursor: pointer;
  background-color: var(--header-background);
  color: var(--text-color);
  border: 1px solid var(--accent-color);
  font-size: 1.1em; /* Increased font size */
  transition: background-color 0.3s, color 0.3s;
  margin: 0 5px; /* Add space between tabs */
  border-radius: 4px; /* Add rounded corners */
}

.tab.active {
  background-color: var(--accent-color);
  color: var(--background-color);
  border-bottom: none;
}

.tab:hover {
  background-color: var(--accent-color); /* Change background on hover */
  color: var(--background-color);
}

/* Tabs Responsive Adjustments */
@media (max-width: 768px) {
  .tabs {
    flex-direction: row; /* Ensure tabs stay in one line */
    overflow-x: auto; /* Allow scrolling if tabs overflow */
    white-space: nowrap; /* Prevent tabs from wrapping */
    zoom: 80%;
  }

  .tab {
    padding: 10px 15px; /* Adjust padding for mobile */
    font-size: 1em; /* Adjust font size for mobile */
  }
}

/* Signup Cards */
.signup-container {
  padding: 20px;
}

.signup-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.signup-card {
  background-color: var(--header-background);
  border: 1px solid var(--accent-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: box-shadow 0.3s;
}

.signup-card h2 {
  color: var(--accent-color);
}

.signup-card .price {
  color: var(--accent-color);
  font-size: 24px;
  font-weight: bold;
}

.signup-card ul {
  list-style: none;
  padding: 0;
}

.signup-card ul li {
  margin-bottom: 10px;
}

.signup-card.selected {
  box-shadow: 0 0 15px var(--accent-color);
}

.checkout-button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 20px;
  width: auto;
  max-width: 200px;
}

.checkout-button:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

/* Billing Overlay */
.billing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
}

.billing-overlay.show {
  visibility: visible;
}

.billing-content {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 90%;
  position: relative;
}

.billing-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
}

.billing-close:hover {
  color: var(--accent-color);
}

.billing-content iframe {
  width: 100%;
  height: 600px;
  border: none;
}

.manage-billing-btn {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
}

.manage-billing-btn:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

/* User Info Page */
.user-info-container {
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
  text-align: left;
}

.user-info {
  margin-top: 20px;
}

.user-info label {
  display: block;
  margin-bottom: 15px;
}

.user-info label strong {
  display: block;
  margin-bottom: 5px;
}

.user-info input {
  width: 90%;
  padding: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.user-info p {
  margin-top: 5px;
  font-size: 0.9em;
  color: var(--text-color);
}

.back-button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 20px;
}

.back-button:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

/* Rules Table */
.rules-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.rules-table th, .rules-table td {
  padding: 10px;
  text-align: left;
  border: none;
}

.rules-table th {
  background-color: var(--accent-color);
  color: var(--background-color);
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.rules-table tr:nth-child(even) {
  background-color: var(--table-row-even);
}

.rules-table tr:nth-child(odd) {
  background-color: var(--table-row-odd);
}

.rules-table tr:hover {
  background-color: var(--table-hover-color);
}

.rules-table-container {
  width: 100%;
  overflow-x: auto;
}

.rules-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  min-width: 400px;
}

.rules-table th, .rules-table td {
  padding: 10px;
  text-align: left;
  border: none;
}

.rules-table th {
  background-color: var(--accent-color);
  color: var(--background-color);
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.rules-table tr:nth-child(even) {
  background-color: var(--table-row-even);
}

.rules-table tr:nth-child(odd) {
  background-color: var(--table-row-odd);
}

.rules-table tr:hover {
  background-color: var(--table-hover-color);
}

.new-rule-form {
  margin-top: 20px;
  background-color: var(--header-background);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.new-rule-form input {
  padding: 10px;
  margin-bottom: 10px;
  width: calc(33% - 20px);
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.new-rule-form button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
}

.new-rule-form button:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}

select {
  width: calc(33% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: var(--input-background);
  color: var(--text-color);
  font-family: var(--header-font);
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l4 4 4-4'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}

select:focus {
  outline: none;
  border-color: var(--accent-color);
}

option {
  background-color: var(--input-background);
  color: var(--text-color);
}

/* Delete Button Styling */
.delete-btn {
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 10px;
}

.delete-btn:hover {
  transform: translateY(-2px);
  background-color: #c0392b;
}

/* View Button Styling */
.view-details-btn {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 10px;
}

.view-details-btn:hover {
  transform: translateY(-2px);
  background-color: var(--accent-color);
}


